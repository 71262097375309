import React from 'react';
import axios from 'axios';

import nclicker from '../assets/images/nclicker.png';
import nclickermc from '../assets/images/nclickermc.png';

import '../styles/nclicker.css';

function NClicker() {

    const updateMetrics = () => {
        const lastDownload = localStorage.getItem("lastDownload");
        const now = Date.now();
    
        if (lastDownload && now - lastDownload < 6000)
            return;
    
        axios.patch("https://api.e-craft.pl/addnc")
            .then((res) => {
                window.location.href = "https://e-craft.pl/dl/NClicker.zip";
                localStorage.setItem("lastDownload", now);
            })
            .catch((err) => console.error('jakis blad 2'));
    };

	return (
        <div className="nclicker">

            <div className="nclicker-d1">

                <div className="nclicker-d1-left">
                    <img id="nc-app" src={nclicker} alt="Obraz 1" />
                </div>

                <div className="nclicker-d1-right">
                    <p className="server-description-header"> Co to NClicker? </p>
                    <p>
                        Jest to prosty auto-clicker stworzony z myślą o serwerach RPG <br /> 
                        do używania bez ryzyka dostania bana za to, że odeszliśmy <br /> 
                        od komputera zapominając o pozostawionym klikerze.
                    </p>
                    <button onClick={updateMetrics} className="nclicker-dl-btn"> POBIERZ NCLICKER </button>
                </div>

            </div>

            <div className="nclicker-d2">
                <p> Gra staje sie od razu przyjemniejsza! </p>
                <img id="nc-game" src={nclickermc} alt="Obraz 2" />
            </div>

        </div>
    )
}

export default NClicker;
